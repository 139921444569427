export default {
    methods: {
        judgeDescription(score) {
            let RETURN;
            this.resultConfig.description.forEach((element) => {
                if (element.condition(score))
                    RETURN = element.copywriting;
            });
            console.log(RETURN);
            return RETURN;
        },
    },
};