<template>
    <PageWrapper
        class="result-wrapper"
        :style="{
            'background-image': `url(${require(`@/assets/game/stages/result-bg/stage${config.stage}.jpg`)})`,
        }"
    >
        <div class="header">
            <div v-if="config.headerIcon" class="icon">
                <img :src="config.headerIcon" alt="" />
            </div>
            <div class="text" :style="{ color: config.fontColor }">
                {{ config.header }}
            </div>
        </div>
        <div class="score">
            <div class="text">
                <div class="label">你的分數</div>
                <div class="number">{{ result }}</div>
            </div>
            <img
                src="@/assets/game/stages/result-bg/star.png"
                alt=""
                height="100%"
            />
        </div>
        <div class="desc">
            <slot />
        </div>
        <div class="button-set">
            <router-link v-if="lastStage" to="/game/form" class="draw">
                登入抽獎
            </router-link>
            <router-link v-else to="/game/map" class="back-to-home">
                回主頁
            </router-link>
            <div class="play-again" @click="$router.go(-1)">再玩一次</div>
        </div>
    </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/Game/Wrappers/PageWrapper.vue";

export default {
    components: { PageWrapper },
    props: {
        config: Object,
        result: Number,
        lastStage: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.result-wrapper {
    display: flex;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .header {
        width: 90%;
        height: 40px;
        margin: 20px auto;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        background-color: #fff;
        align-items: center;
        .icon {
            width: 55px;
        }
        .text {
            font-size: 20px;
            font-weight: bold;
        }
    }
    .score {
        position: relative;
        height: 40%;
        img {
            margin: auto;
        }
        .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -40%);
            font-size: 20px;
            font-weight: bold;
            line-height: 0.95;
            text-align: center;
            color: #ce4a4a;
        }
        .number {
            font-size: 92px;
            margin-top: 20px;
        }
    }
    .desc {
        width: 90%;
        padding: 10px;
        margin: 20px auto;
        border: solid 2px #ffffff;
        border-radius: 30px;
        color: #ffffff;
        text-align: center;
        letter-spacing: normal;
        white-space: pre-line;
    }
    .button-set {
        flex: 1;
        width: 160px;
        margin: auto;
        font-size: 26px;
        font-weight: bold;
        text-align: center;
        > * {
            cursor: pointer;
            display: block;
            white-space: nowrap;
            border-radius: 7px;
            padding: 10px 30px;
        }
        .draw {
            color: #ffffff;
            background-color: #ed6f6f;
            box-shadow: -10px 10px 0px 1px #cc5d5d;
        }
        .back-to-home {
            color: #755c1c;
            background-color: #fee4a4;
            box-shadow: -10px 10px 0px 1px #b18f39;
        }
        .play-again {
            margin-top: 20px;
            color: #707070;
            background-color: #e3e3e3;
            box-shadow: -10px 10px 0px 1px #a8a7a5;
        }
    }
    @media (max-height: 670px) {
        .score {
            height: 35%;
            .text {
                transform: translate(-50%, -30%);
            }
            .number {
                font-size: 70px;
                margin-top: 5px;
            }
        }
        .desc {
            font-size: 14px;
            padding: 7px;
            height: auto;
        }
        .button-set {
            font-size: 20px;
        }
    }
}
</style>
