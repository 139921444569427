import STAGE00_HEADER_ICON from "@/assets/game/stages/stage0/result-icon.png";

export default {
  stage0: {
    stage: 0,
    headerIcon: STAGE00_HEADER_ICON,
    header: "暖身題",
    fontColor: "#169fba",
    description: [
      {
        condition: (input) => input <= 5,
        copywriting:
          "恭喜您！身心適應狀況良好，\n多與他人分享您處理壓力的心得吧!",
      },
      {
        condition: (input) => input >= 6 && input <= 9,
        copywriting: "輕度情緒困擾，建議找家人或朋友談談，抒發情緒。",
      },
      {
        condition: (input) => input >= 10 && input <= 14,
        copywriting: "中度情緒困擾，建議尋求心理商或接受專業詢。",
      },
      {
        condition: (input) => input >= 15,
        copywriting:
          "重度情緒困擾，需要高度關懷，建議尋求專業輔導或精神科治療。",
      },
    ],
  },
  stage1: {
    stage: 1, // @/assets/game/stages/result-bg/stage${stage}.jpg
    header: "關卡1",
    fontColor: "#ed6f6f",
    description: [
      {
        condition: (input) => input >= 0,
        copywriting: 5,
      },
      {
        condition: (input) => input >= 1,
        copywriting: 11,
      },
      {
        condition: (input) => input >= 2,
        copywriting: 17,
      },
      {
        condition: (input) => input >= 3,
        copywriting: 23,
      },
      {
        condition: (input) => input >= 4,
        copywriting: 29,
      },
      {
        condition: (input) => input >= 5,
        copywriting: 35,
      },
      {
        condition: (input) => input >= 6,
        copywriting: 41,
      },
      {
        condition: (input) => input >= 7,
        copywriting: 47,
      },
      {
        condition: (input) => input >= 8,
        copywriting: 53,
      },
      {
        condition: (input) => input >= 9,
        copywriting: 59,
      },
      {
        condition: (input) => input >= 10,
        copywriting: 61,
      },
      {
        condition: (input) => input >= 11,
        copywriting: 67,
      },
      {
        condition: (input) => input >= 12,
        copywriting: 73,
      },
      {
        condition: (input) => input >= 13,
        copywriting: 79,
      },
      {
        condition: (input) => input >= 14,
        copywriting: 85,
      },
      {
        condition: (input) => input >= 15,
        copywriting: 90,
      },
      {
        condition: (input) => input >= 20,
        copywriting: 95,
      },
    ],
  },
  stage2: {
    stage: 2, // @/assets/game/stages/result-bg/stage${stage}.jpg
    header: "關卡2",
    fontColor: "#2c5ca1",
    description: [
      {
        condition: (input) => input >= 0,
        copywriting: 5,
      },
      {
        condition: (input) => input >= 1,
        copywriting: 11,
      },
      {
        condition: (input) => input >= 2,
        copywriting: 17,
      },
      {
        condition: (input) => input >= 3,
        copywriting: 23,
      },
      {
        condition: (input) => input >= 4,
        copywriting: 29,
      },
      {
        condition: (input) => input >= 5,
        copywriting: 35,
      },
      {
        condition: (input) => input >= 6,
        copywriting: 41,
      },
      {
        condition: (input) => input >= 7,
        copywriting: 47,
      },
      {
        condition: (input) => input >= 8,
        copywriting: 53,
      },
      {
        condition: (input) => input >= 9,
        copywriting: 59,
      },
      {
        condition: (input) => input >= 10,
        copywriting: 61,
      },
      {
        condition: (input) => input >= 11,
        copywriting: 67,
      },
      {
        condition: (input) => input >= 12,
        copywriting: 73,
      },
      {
        condition: (input) => input >= 13,
        copywriting: 79,
      },
      {
        condition: (input) => input >= 14,
        copywriting: 85,
      },
      {
        condition: (input) => input >= 15,
        copywriting: 90,
      },
      {
        condition: (input) => input >= 20,
        copywriting: 95,
      },
    ],
  },
  stage3: {
    stage: 3, // @/assets/game/stages/result-bg/stage${stage}.jpg
    header: "關卡3",
    fontColor: "#d4762e",
    description: [
      {
        condition: (input) => input < 20,
        copywriting: 0,
      },
      {
        condition: (input) => input >= 40,
        copywriting: 5,
      },
      {
        condition: (input) => input >= 60,
        copywriting: 11,
      },
      {
        condition: (input) => input >= 80,
        copywriting: 17,
      },
      {
        condition: (input) => input >= 100,
        copywriting: 23,
      },
      {
        condition: (input) => input >= 120,
        copywriting: 29,
      },
      {
        condition: (input) => input >= 140,
        copywriting: 35,
      },
      {
        condition: (input) => input >= 160,
        copywriting: 41,
      },
      {
        condition: (input) => input >= 180,
        copywriting: 47,
      },
      {
        condition: (input) => input >= 200,
        copywriting: 53,
      },
      {
        condition: (input) => input >= 220,
        copywriting: 59,
      },
      {
        condition: (input) => input >= 240,
        copywriting: 61,
      },
      {
        condition: (input) => input >= 260,
        copywriting: 67,
      },
      {
        condition: (input) => input >= 280,
        copywriting: 73,
      },
      {
        condition: (input) => input >= 310,
        copywriting: 79,
      },
      {
        condition: (input) => input >= 340,
        copywriting: 85,
      },
      {
        condition: (input) => input >= 370,
        copywriting: 90,
      },
      {
        condition: (input) => input >= 400,
        copywriting: 95,
      },
    ],
  },
  stage4: {
    stage: 4, // @/assets/game/stages/result-bg/stage${stage}.jpg
    header: "關卡4",
    fontColor: "#6b9634",
    description: [
      {
        condition: (input) => input >= 0,
        copywriting: 5,
      },
      {
        condition: (input) => input >= 1,
        copywriting: 11,
      },
      {
        condition: (input) => input >= 2,
        copywriting: 17,
      },
      {
        condition: (input) => input >= 3,
        copywriting: 23,
      },
      {
        condition: (input) => input >= 4,
        copywriting: 29,
      },
      {
        condition: (input) => input >= 5,
        copywriting: 35,
      },
      {
        condition: (input) => input >= 6,
        copywriting: 41,
      },
      {
        condition: (input) => input >= 7,
        copywriting: 47,
      },
      {
        condition: (input) => input >= 8,
        copywriting: 53,
      },
      {
        condition: (input) => input >= 9,
        copywriting: 59,
      },
      {
        condition: (input) => input >= 10,
        copywriting: 61,
      },
      {
        condition: (input) => input >= 11,
        copywriting: 67,
      },
      {
        condition: (input) => input >= 12,
        copywriting: 73,
      },
      {
        condition: (input) => input >= 13,
        copywriting: 79,
      },
      {
        condition: (input) => input >= 14,
        copywriting: 85,
      },
      {
        condition: (input) => input >= 15,
        copywriting: 90,
      },
      {
        condition: (input) => input >= 20,
        copywriting: 95,
      },
    ],
  },
  stage5: {
    stage: 5, // @/assets/game/stages/result-bg/stage${stage}.jpg
    header: "關卡5",
    fontColor: "#7f49aa",
    description: [
      {
        condition: (input) => input >= 0,
        copywriting: 5,
      },
      {
        condition: (input) => input >= 1,
        copywriting: 11,
      },
      {
        condition: (input) => input >= 2,
        copywriting: 17,
      },
      {
        condition: (input) => input >= 3,
        copywriting: 23,
      },
      {
        condition: (input) => input >= 4,
        copywriting: 29,
      },
      {
        condition: (input) => input >= 5,
        copywriting: 35,
      },
      {
        condition: (input) => input >= 6,
        copywriting: 41,
      },
      {
        condition: (input) => input >= 7,
        copywriting: 47,
      },
      {
        condition: (input) => input >= 8,
        copywriting: 53,
      },
      {
        condition: (input) => input >= 9,
        copywriting: 59,
      },
      {
        condition: (input) => input >= 10,
        copywriting: 61,
      },
      {
        condition: (input) => input >= 11,
        copywriting: 67,
      },
      {
        condition: (input) => input >= 12,
        copywriting: 73,
      },
      {
        condition: (input) => input >= 13,
        copywriting: 79,
      },
      {
        condition: (input) => input >= 14,
        copywriting: 85,
      },
      {
        condition: (input) => input >= 15,
        copywriting: 90,
      },
      {
        condition: (input) => input >= 20,
        copywriting: 95,
      },
      {
        condition: (input) => input >= 25,
        copywriting: 98,
      },
    ],
  },
};
