<template>
  <div class="result-stage0">
    <ResultWrapper :config="resultConfig" :result="score">
      <CompetitionResult :percentage="judgeDescription(score)" />
    </ResultWrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ResultWrapper from '@/components/Game/Wrappers/ResultWrapper.vue';
import CompetitionResult from '@/components/Game/Results/CompetitionResult.vue';
import CONFIG from '@/config/resultConfig';
import ResultMixin from '@/mixins/result';

export default {
  mixins: [ResultMixin],
  components: { ResultWrapper, CompetitionResult },
  data() {
    return {
      resultConfig: CONFIG.stage3,
    };
  },
  computed: {
    ...mapState({
      score: (state) => state.score,
    }),
  },
};
</script>
