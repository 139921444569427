<template>
    <div class="result-stage0">
        <ResultWrapper :config="resultConfig" :result="score">
            {{ judgeDescription(score) }}
        </ResultWrapper>
    </div>
</template>

<script>
import { mapState } from "vuex";
import ResultWrapper from "@/components/Game/Wrappers/ResultWrapper.vue";
import CONFIG from "@/config/resultConfig";
import ResultMixin from "@/mixins/result";

export default {
    mixins: [ResultMixin],
    components: { ResultWrapper },
    data() {
        return {
            resultConfig: CONFIG.stage0,
        };
    },
    computed: {
        ...mapState({
            score: (state) => state.score,
        }),
    },
};
</script>
