import { render, staticRenderFns } from "./CompetitionResult.vue?vue&type=template&id=0bc2ff78&scoped=true&"
import script from "./CompetitionResult.vue?vue&type=script&lang=js&"
export * from "./CompetitionResult.vue?vue&type=script&lang=js&"
import style0 from "./CompetitionResult.vue?vue&type=style&index=0&id=0bc2ff78&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc2ff78",
  null
  
)

export default component.exports