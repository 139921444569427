<template>
    <div class="result" :style="cssStyle">
        <component :is="currentStageComponent" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import Stage0 from "@/components/Game/Results/Stage0.vue";
import Stage1 from "@/components/Game/Results/Stage1.vue";
import Stage2 from "@/components/Game/Results/Stage2.vue";
import Stage3 from "@/components/Game/Results/Stage3.vue";
import Stage4 from "@/components/Game/Results/Stage4.vue";
import Stage5 from "@/components/Game/Results/Stage5.vue";

export default {
    components: { Stage0, Stage1, Stage2, Stage3, Stage4, Stage5 },
    computed: {
        ...mapState({
            score: (state) => state.score,
        }),
        currentStageComponent() {
            return `Stage${this.$route.params.stage}`;
        },
        cssStyle() {
            return `--navbar-height: ${this.$root.navbarHeight}px`;
        },
    },
    created() {
        if (this.score === undefined) this.$router.push({ name: "Game_Map" });
    },
};
</script>

<style lang="scss" scoped>
.result {
    height: calc(100vh - var(--navbar-height));
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
