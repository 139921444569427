<template>
    <div class="competition-result">
        <div class="upper">
            <div class="triangle"></div>
            <div class="text">你贏過世界上</div>
        </div>
        <div class="result">
            <span>{{ percentage }}</span> %的人
        </div>
    </div>
</template>

<script>
export default {
    props: {
        percentage: Number,
    },
};
</script>

<style lang="scss" scoped>
.competition-result {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: 28px;
    .upper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .triangle {
        margin-right: 5px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 20px solid #fff;
    }
    .result {
        display: flex;
        align-items: flex-end;
        margin-top: 5px;
        span {
            font-size: 60px;
            line-height: 1;
        }
    }
    @media (max-height: 670px) {
        font-size: 20px;
        .result span {
            font-size: 50px;
        }
        .triangle {
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 15px solid #fff;
        }
    }
}
</style>
